import { createBrowserRouter, Navigate } from 'react-router-dom';
import Main from "../landing/main";


const router = createBrowserRouter([
  {
    path: '/search',
    element: <Main />,
  },
  {
    path: '/',
    element: <Navigate replace to="/search" />,
  }
]);
export default router;