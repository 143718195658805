import * as R from "ramda";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import Auth from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Wrapper,
  FormGroup,
  Button,
  Modal,
  TextInput,
  Blockquote,
} from "@wfp/ui";
import { iconSearch } from "@wfp/icons";
import queryString from "query-string";
import Select from "react-select";
import {
  loadChoices as loadChoicesFn,
  loadFromLocalStorage as loadFromLocalStorageFn,
  CHOICE_TYPE_OPTIONS,
  SHOW_ALL_OPTION,
} from "../reducers/choicesReducer";
import {
  loadOrganizations as loadOrganizationsFn,
  clearOrganizations as clearOrganizationsFn,
} from "../reducers/organizationReducer";
import { loadResults as loadResultsFn, clearResults as clearResultsFn } from "../reducers/resultsReducer";
import { resultsParams, isEmptyFilters } from "../utils/params";

const texts = {
  info: "Search for an Annual Country Report (ACR) or Standard Project Report (SPR) by selecting one or more of the criteria listed below.​",
  info_2: " Choosing more than one criteria will give more accurate results.​",
  countryRecipient: "Country Recipient",
  year: "Year",
  reportType: "Report Type",
  donor: "Donor",
  organization: "Organization",
  reportCategory: "Project Type",
  filterResults: "Filter Results",
  projectNumber: "Project Number",
  reset: "Reset",
  selectFilter: "Please, fill in at least one field.",
  ok: "OK",
};

export const FILTER_FIELDS = {
  COUNTRY: "country_name",
  YEAR: "spr_year",
  REPORT: "project_status",
  DONOR: "donor_name",
  ORGANIZATION: "org_name",
  CATEGORY: "project_category",
  PROJECT_NUMBER: "project_number",
};

// class Search extends Component {

const Search = ({
  loadFromLocalStorage,
  loadChoices,
  loadOrganizations,
  clearResults,
  clearOrganizations,
  loading,
  yearLoading,
  yearData,
  countryLoading,
  countryData,
  categoryLoading,
  categoryData,
  donorLoading,
  donorData,
  organizationsLoading,
  organizationsData,
  reportTypeLoading,
  reportTypeData,
  loadResults
}) => {
  const [filtersModal, setFiltersModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    loadFromLocalStorage(CHOICE_TYPE_OPTIONS.YEAR);
    loadFromLocalStorage(CHOICE_TYPE_OPTIONS.COUNTRY);
    loadFromLocalStorage(CHOICE_TYPE_OPTIONS.DONOR);
    loadFromLocalStorage(CHOICE_TYPE_OPTIONS.REPORT);
    loadFromLocalStorage(CHOICE_TYPE_OPTIONS.CATEGORY);

    loadFilters();
  }, [loadFromLocalStorage]);

  function loadFilters() {
    const queryParams = queryString.parse(location.search);

    if (queryParams[FILTER_FIELDS.DONOR]) {
      loadOrganizations(queryParams[FILTER_FIELDS.DONOR]);
    }

    loadChoices(CHOICE_TYPE_OPTIONS.YEAR);
    loadChoices(CHOICE_TYPE_OPTIONS.COUNTRY);
    loadChoices(CHOICE_TYPE_OPTIONS.DONOR);
    loadChoices(CHOICE_TYPE_OPTIONS.REPORT);
    loadChoices(CHOICE_TYPE_OPTIONS.CATEGORY);
  }

  function onSelectionChange(field, value) {
    let queryParams = queryString.parse(location.search);

    if (value.value === SHOW_ALL_OPTION.value) {
      if (field === FILTER_FIELDS.DONOR) {
        clearOrganizations();

        queryParams = R.dissoc(FILTER_FIELDS.ORGANIZATION, queryParams);
      }
      queryParams = R.dissoc(field, queryParams);

      navigate(`/search?${queryString.stringify(queryParams)}`);

      return;
    }

    if (!R.isEmpty(value.value) && !R.isNil(value.value)) {
      queryParams = R.assoc(field, value.value, queryParams);
    } else {
      queryParams = R.dissoc(field, queryParams);
    }

    if (field === FILTER_FIELDS.DONOR) {
      queryParams = R.dissoc(FILTER_FIELDS.ORGANIZATION, queryParams);

      loadOrganizations(value.value);
    }

    navigate(`/search?${queryString.stringify(queryParams)}`);
  }

  function selectedValue(type, data) {
    const queryParams = queryString.parse(location.search);
    let selectedOption = null;

    if (data) {
      const queryValue = R.find(R.propEq("value", queryParams[type]), data);

      if (queryValue && queryValue !== SHOW_ALL_OPTION.value) {
        selectedOption = {
          value: queryParams[type],
          label: queryValue.label,
        };
      } else {
        selectedOption = SHOW_ALL_OPTION;
      }
    } else if (type === FILTER_FIELDS.PROJECT_NUMBER) {
      return queryParams[type] || "";
    }

    return selectedOption;
  }

  function onReset() {
    clearOrganizations();
    clearResults();

    const queryParams = queryString.parse(location.search);

    navigate(`/search?${queryString.stringify({
      page: 0,
      page_size: queryParams["page_size"],
    })}`);
  }

  function onInitialSearch() {
    clearResults();
    let queryParams = queryString.parse(location.search);
    queryParams = R.assoc("page", Number(0), queryParams);


    navigate(`/search?${queryString.stringify(queryParams)}`);
  }

  return (
    <div style={{ height: "100%" }}>
      <Modal
        onRequestClose={() => setFiltersModal(false)}
        open={filtersModal}
        modalHeading={"Filters"}
        passiveModal={true}
      >
        <div className="modalRow">
          <div> {texts.selectFilter}</div>
          <div>
            <Button
              className={"modalButton"}
              onClick={() => setFiltersModal(false)}
            >
              {texts.ok}
            </Button>
          </div>
        </div>
      </Modal>
      <Wrapper pageWidth="lg">
        <div className="blockquote">
          <Blockquote>
            <div>{texts.info}</div>
            <div>{texts.info_2}</div>
          </Blockquote>
        </div>
        <FormGroup>
          <div className="row">
            <div className="col-xs">
              <label className="wfp--label">{texts.countryRecipient}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                id={FILTER_FIELDS.COUNTRY}
                value={selectedValue(FILTER_FIELDS.COUNTRY, countryData)}
                isLoading={countryLoading && R.isEmpty(countryData)}
                options={countryData}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.COUNTRY, value)
                }
              />
            </div>
            <div className="col-xs">
              <label className="wfp--label">{texts.projectNumber}</label>
              <TextInput
                id={FILTER_FIELDS.PROJECT_NUMBER}
                labelText=""
                onChange={(event) =>
                  onSelectionChange(FILTER_FIELDS.PROJECT_NUMBER, {
                    value: R.trim(event.target.value),
                  })
                }
                value={selectedValue(FILTER_FIELDS.PROJECT_NUMBER)}
                className="searchTextInput"
              />
            </div>
            <div className="col-xs">
              <label className="wfp--label">{texts.year}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                id={FILTER_FIELDS.YEAR}
                value={selectedValue(FILTER_FIELDS.YEAR, yearData)}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.YEAR, value)
                }
                isLoading={yearLoading && R.isEmpty(yearData)}
                options={yearData}
              />
            </div>
            <div className="col-xs">
              <label className="wfp--label">{texts.reportType}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                id={FILTER_FIELDS.REPORT}
                value={selectedValue(FILTER_FIELDS.REPORT, reportTypeData)}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.REPORT, value)
                }
                isLoading={reportTypeLoading && R.isEmpty(reportTypeData)}
                options={reportTypeData}
              />
            </div>
          </div>
          <div className="row marginMedium">
            <div className="col-xs">
              <label className="wfp--label">{texts.donor}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                value={selectedValue(FILTER_FIELDS.DONOR, donorData)}
                id={FILTER_FIELDS.DONOR}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.DONOR, value)
                }
                isLoading={donorLoading && R.isEmpty(donorData)}
                options={donorData}
              />
            </div>
            <div className="col-xs">
              <label className="wfp--label">{texts.organization}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                id={FILTER_FIELDS.ORGANIZATION}
                value={selectedValue(
                  FILTER_FIELDS.ORGANIZATION,
                  organizationsData
                )}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.ORGANIZATION, value)
                }
                isLoading={organizationsLoading}
                options={organizationsData}
              />
            </div>
            <div className="col-xs">
              <label className="wfp--label">{texts.reportCategory}</label>
              <Select
                className="wfp--react-select-container"
                classNamePrefix="wfp--react-select"
                id={FILTER_FIELDS.CATEGORY}
                value={selectedValue(FILTER_FIELDS.CATEGORY, categoryData)}
                onChange={(value) =>
                  onSelectionChange(FILTER_FIELDS.CATEGORY, value)
                }
                isLoading={categoryLoading && R.isEmpty(categoryData)}
                options={categoryData}
              />
            </div>
          </div>
          <div className="marginSmall end-xs">
            <div className="buttonContainer col-xs">
              <Button
                className="buttonRight"
                disabled={isEmptyFilters(location.search)}
                onClick={() => onReset()}
                kind="secondary"
              >
                {texts.reset}
              </Button>
              <Button
                onClick={() => {
                  if (isEmptyFilters(location.search)) {
                    setFiltersModal(true);
                  } else {
                    onInitialSearch();
                    loadResults(resultsParams(location.search, true));
                  }
                }}
                disabled={loading}
                iconDescription="search"
                icon={iconSearch}
              >
                {texts.filterResults}
              </Button>
            </div>
          </div>
        </FormGroup>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    donorLoading: R.pathOr(false, ["choices", "donor", "loading"], state),
    donorData: R.pathOr([], ["choices", "donor", "data"], state),
    categoryLoading: R.pathOr(false, ["choices", "category", "loading"], state),
    categoryData: R.pathOr([], ["choices", "category", "data"], state),
    yearLoading: R.pathOr(false, ["choices", "year", "loading"], state),
    yearData: R.pathOr([], ["choices", "year", "data"], state),
    reportTypeLoading: R.pathOr(false, ["choices", "report", "loading"], state),
    reportTypeData: R.pathOr([], ["choices", "report", "data"], state),
    countryLoading: R.pathOr(false, ["choices", "country", "loading"], state),
    countryData: R.pathOr([], ["choices", "country", "data"], state),
    organizationsData: R.pathOr([], ["organizations", "data"], state),
    organizationsLoading: R.pathOr(false, ["organizations", "loading"], state),
    loading: R.pathOr(false, ["results", "loading"], state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFromLocalStorage: (choiceType) =>
    dispatch(loadFromLocalStorageFn(choiceType)),
  loadChoices: (choiceType) => dispatch(loadChoicesFn(choiceType)),
  loadOrganizations: (donorType) => dispatch(loadOrganizationsFn(donorType)),
  loadResults: (params) => dispatch(loadResultsFn(params)),
  clearOrganizations: () => dispatch(clearOrganizationsFn()),
  clearResults: () => dispatch(clearResultsFn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
