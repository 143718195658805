import * as R from "ramda";
import { fetchResults } from "../api/api";
import router from "../utils/router";

const CLEAR_RESULTS = "CLEAR_RESULTS";
const LOAD_STARTED_RESULTS = "LOAD_STARTED_RESULTS";
const LOAD_ENDED_RESULTS = "LOAD_ENDED_RESULTS";
const LOAD_SUCCESS_RESULTS = "LOAD_SUCCESS_RESULTS";
const LOAD_FAILURE_RESULTS = "LOAD_FAILURE_RESULTS";

export const clearResults = () => ({
  type: CLEAR_RESULTS,
});

const loadStarted = () => ({
  type: LOAD_STARTED_RESULTS,
});

const loadEnded = () => ({
  type: LOAD_ENDED_RESULTS,
});

const loadSuccess = (data) => ({
  type: LOAD_SUCCESS_RESULTS,
  data,
});

const loadFailure = (data) => ({
  type: LOAD_FAILURE_RESULTS,
  data,
});

export const loadResults = (params) => (dispatch) => {
  dispatch(loadStarted());

  return fetchResults(params)
    .then((data) => {
      dispatch(loadEnded());
      dispatch(loadSuccess({ data }));
      return data;
    })
    .catch((error) => {
      const status = R.pathOr(null, ["response", "status"], error);

      if (status === 401) {
        router.navigate("/login");
      }

      dispatch(loadEnded());
      dispatch(loadFailure({ error }));
    });
};

const clear = (state) => {
  const data = R.assocPath(["data"], null, state);

  return R.assocPath(["pagination"], null, data);
};

const saveResults = (state, action) => {
  const data = R.assocPath(["data"], action.data.results, state);

  return R.assocPath(["pagination"], action.data.pagination, data);
};

const saveLoading = (state, action) => {
  return R.assocPath(["loading"], action, state);
};

export default function resultsReducer(
  state = { data: null, pagination: null, loading: false },
  action
) {
  switch (action && action.type) {
    case LOAD_STARTED_RESULTS: {
      return saveLoading(state, true);
    }
    case LOAD_ENDED_RESULTS: {
      return saveLoading(state, false);
    }
    case LOAD_SUCCESS_RESULTS: {
      return saveResults(state, action.data);
    }
    case CLEAR_RESULTS: {
      return clear(state);
    }
    default:
      return state;
  }
}
