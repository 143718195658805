import { Pagination, Loading } from "@wfp/ui";
import React, { Fragment, useEffect } from "react";
import { useFlexLayout, usePagination, useSortBy, useTable } from "react-table";

const defaultColumn = {
  minWidth: 30,
  width: 50,
  maxWidth: 400,
};
const pageCount = -1;

const TableMain = ({
  className = "",
  data,
  columns,
  loading = false,
  totalItems,
  updateUrlParams,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex: pageIndexState, pageSize: pageSizeState, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "country_name",
            desc: false,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount,
      enableSorting: true,
      disableSortRemove: true
    },
    useFlexLayout,
    useSortBy,
    usePagination
  );

  function onPaginationChange({ page, pageSize }) {
    setPageSize(pageSize);
    gotoPage(page);
  }

  useEffect(() => {
    if (pageIndexState) {
      updateUrlParams("page", pageIndexState - 1);
    }
  }, [pageIndexState]);

  useEffect(() => {
    if (pageSizeState) {
      updateUrlParams("page_size", pageSizeState);
    }
  }, [pageSizeState]);

  useEffect(() => {
    updateUrlParams("orderBy", sortBy);
  }, [sortBy]);

  return (
    <div className={`${className ? className : ""} ReactTable`}>
      {loading && <Loading withOverlay={true} active />}
      <div {...getTableProps} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={`${index}-header-group`}
              className="rt-tr"
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`rt-th rt-resizable-header ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "-sort-asc"
                          : "-sort-desc"
                        : ""
                    }`}
                    key={`${index}-header`}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="rt-tbody">
          <div {...getTableBodyProps()} className="rt-tr-group">
            {page.map((row, ind) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <div
                    {...row.getRowProps()}
                    className={`rt-tr ${ind % 2 === 0 ? "-odd" : "-even"}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          key={`${index}-cell`}
                          className={`rt-td ${cell.column.className}`}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <Pagination
        onChange={onPaginationChange}
        pageSizes={[10, 20, 30]}
        totalItems={totalItems}
        pages={pageCount}
      />
    </div>
  );
};

export default TableMain;
