import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MainNavigation, MainNavigationItem, Button } from "@wfp/ui";
import { clearOrganizations } from "../reducers/organizationReducer";
import { clearResults } from "../reducers/resultsReducer";
import { Auth } from "aws-amplify";

const texts = {
  website: "https://annualreports.wfp.org/",
  wfp: "WFP Annual Country Reports",
  logout: "Log out",
};

class Header extends Component {
  render() {

    const { clearResults, clearOrganizations } = this.props;

    return (
      <div>
        <MainNavigation
          children=""
          logo={<a href={texts.website}>{texts.wfp}</a>}
        >
          <MainNavigationItem>
            <Button
              kind="navigation"
              small
              className="logout"
              onClick={() => {
                clearOrganizations();
                clearResults();
                window.localStorage.clear();
                Auth.signOut().then(() => (
                  window.location.href = "/"
                ));
              }}
            >
              {texts.logout}
            </Button>
          </MainNavigationItem>
        </MainNavigation>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearResults: () => dispatch(clearResults()),
  clearOrganizations: () => dispatch(clearOrganizations()),
});

export default compose(connect(null, mapDispatchToProps))(Header);
