import * as R from "ramda";
// import Auth from "@aws-amplify/auth";
import axios from "axios";
import store from "../AppStore";

const host = `${process.env.REACT_APP_API_GATEWAY_URL}`;

/* TODO: Remove and improve, would return endless requests
let fetchingToken = false;
let callback = null;

function onAccessTokenFetched(token) {
  callback(token);
}

function addSubscriber(subscriber) {
  callback = subscriber;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    const status = R.pathOr(null, ["status"], response);
    const originalRequest = config;

    if (status === 401) {
      if (!fetchingToken) {
        fetchingToken = true;

        Auth.currentCredentials().then((credentials) => {
          const statusCode = R.pathOr(null, ["statusCode"], credentials);
          const expirationTime = R.pathOr(0, ["expireTime"], credentials);

          if (statusCode === 400 || statusCode === 401) {
            history.push("/login");
            return;
          }

          window.localStorage.setItem(
            "token_exp",
            new Date(expirationTime).getTime()
          );

          fetchingToken = false;
          onAccessTokenFetched(getLocalStorageToken());
        });
      }

      const retryRequest = new Promise((resolve) => {
        addSubscriber((token) => {
          originalRequest.headers.Authorization = token;
          resolve(axios(originalRequest));
        });
      });

      return retryRequest;
    }
    return Promise.reject(error);
  }
);*/

export const getLocalStorageToken = () => {
  const localStorageItems = { ...window.localStorage };

  const key = R.filter(
    (item) => R.contains("idToken", item),
    R.keys(localStorageItems)
  );

  if (key.length > 0) {
    return localStorageItems[key[0]];
  }

  return null;
};

function buildHeaders() {
  const token = store.getState().session.token || getLocalStorageToken();
  const headers = { Authorization: `${token}` };

  return { ...headers };
}

function authorizedGet({ uri, params = {}, options }) {
  const opt = {
    params,
    headers: buildHeaders(),
  };

  return axios
    .get(`${host}${uri}`, { ...opt })
    .then((response) => response.data);
}

function authorizedPost({ uri, params, body = {} }) {
  const options = {
    params,
    headers: buildHeaders(),
  };

  return axios
    .post(`${host}${uri}`, body, options)
    .then((response) => response.data);
}

export function fetchFile(fileName) {
  return authorizedGet({
    uri: `/download?filename=${fileName}`,
  });
}

export function fetchChoices(choiceType) {
  return authorizedGet({
    uri: `/choices/?columns=${choiceType}`,
  });
}

export function fetchChoicesStatic(choiceType) {
  return authorizedGet({
    uri: `/choices/${choiceType}`,
  });
}

export function fetchOrganizations(donorCode) {
  return authorizedGet({
    uri: `/choices/organizations/?donor_name=${donorCode}`,
  });
}

export function fetchResults(body) {
  return authorizedPost({
    uri: `/results/`,
    body,
  });
}
