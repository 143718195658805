import * as R from "ramda";
import { fetchOrganizations } from "../api/api";
import { SHOW_ALL_OPTION } from "./choicesReducer";
import { removeQuotes } from "../utils/params";
import router from "../utils/router";

const LOAD_STARTED_ORGANIZATIONS = "LOAD_STARTED_ORGANIZATIONS";
const LOAD_ENDED_ORGANIZATIONS = "LOAD_ENDED_ORGANIZATIONS";
const LOAD_SUCCESS_ORGANIZATIONS = "LOAD_SUCCESS_ORGANIZATIONS";
const LOAD_FAILURE_ORGANIZATIONS = "LOAD_FAILURE_ORGANIZATIONS";

export const clearOrganizations = () => ({
  type: LOAD_SUCCESS_ORGANIZATIONS,
  data: { data: { choices: [] } },
});

const loadStarted = () => ({
  type: LOAD_STARTED_ORGANIZATIONS,
});

const loadEnded = () => ({
  type: LOAD_ENDED_ORGANIZATIONS,
});

const loadSuccess = (data) => ({
  type: LOAD_SUCCESS_ORGANIZATIONS,
  data,
});

const loadFailure = (data) => ({
  type: LOAD_FAILURE_ORGANIZATIONS,
  data,
});

const mapValues = (values) => {
  return values.map((item) => {
    let mapped = {};

    mapped = R.assoc("value", item[0], mapped);
    mapped = R.assoc("label", removeQuotes(item[1]), mapped);

    return mapped;
  });
};

export const loadOrganizations = (donorCode) => (dispatch) => {
  dispatch(loadStarted());
  dispatch(loadSuccess({ data: { choices: [] } }));

  return fetchOrganizations(donorCode)
    .then((data) => {
      dispatch(loadEnded());
      dispatch(loadSuccess({ data }));

      return data;
    })
    .catch((error) => {
      const status = R.pathOr(null, ["response", "status"], error);
      if (status === 401) {
        router.navigate("/login");
      }

      dispatch(loadEnded());
      dispatch(loadFailure({ error }));
    });
};

const saveOrganizations = (state, action) => {
  const uniqueOrganizations = R.values(
    R.reduce(
      (acc, item) => {
        acc[item.value] = item;
        return acc;
      },
      {},
      mapValues(action.data.choices)
    )
  );

  return R.assocPath(
    ["data"],
    R.prepend(SHOW_ALL_OPTION, uniqueOrganizations),
    state
  );
};

const saveLoading = (state, action) => {
  return R.assocPath(["loading"], action, state);
};

export default function organizationsReducer(
  state = { data: [], loading: false },
  action
) {
  switch (action && action.type) {
    case "LOAD_STARTED_ORGANIZATIONS": {
      return saveLoading(state, true);
    }
    case "LOAD_ENDED_ORGANIZATIONS": {
      return saveLoading(state, false);
    }
    case "LOAD_SUCCESS_ORGANIZATIONS": {
      return saveOrganizations(state, action.data);
    }
    default:
      return state;
  }
}
