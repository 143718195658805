import React, { Component } from "react";
import Header from "./header";
import Search from "./search";
import Results from "./results";
import FooterHeader from "./footer";

class Main extends Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <Search />
        <Results />
        <FooterHeader />
      </div>
    );
  }
}

export default Main;
