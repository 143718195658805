import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import session from "./reducers/sessionReducer";
import results from "./reducers/resultsReducer";
import organizations from "./reducers/organizationReducer";
import choices from "./reducers/choicesReducer";
import axiosInterceptors from "./axiosInterceptors";

const composeEnhancers =
  process.env.NODE_ENV !== "production"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const middleware = [thunk];

const mainReducer = combineReducers({
  session,
  results,
  organizations,
  choices,
});

axiosInterceptors.setupInterceptors();

export default createStore(
  mainReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
