import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./utils/router";
import "./App.scss";
import { Provider } from "react-redux";
import appStore from "./AppStore";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./api/awsconfig.json";
import awsauth from "./api/awsauth.json";
import ReactGA from "react-ga";

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA); // G-H1J63KEQ3K
  ReactGA.pageview(window.location.pathname + window.location.search);
}

awsconfig["Auth"][
  "identityPoolId"
] = `${process.env.REACT_APP_AUTH_IDENTITY_POOL_ID}`;
awsconfig["Auth"]["userPoolId"] = `${process.env.REACT_APP_AUTH_POOL_ID}`;
awsconfig["Auth"][
  "userPoolWebClientId"
] = `${process.env.REACT_APP_AUTH_CLIENT_ID}`;
awsconfig["Auth"][
  "redirectSignIn"
] = `${process.env.REACT_APP_AUTH_REDIRECT_URL}`;
awsconfig["Auth"][
  "redirectSignOut"
] = `${process.env.REACT_APP_AUTH_REDIRECT_URL}`;

awsauth["redirectSignIn"] = `${process.env.REACT_APP_AUTH_REDIRECT_URL}`;
awsauth["redirectSignOut"] = `${process.env.REACT_APP_AUTH_REDIRECT_URL}`;
awsauth["domain"] = `${process.env.REACT_APP_AUTH_DOMAIN}`;

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth });

const App = () => {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {})
      .catch((error) => {
        Auth.federatedSignIn();
      });
  }, []);

  return (
    <Provider store={appStore}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
