import * as R from "ramda";
import { fetchChoices, fetchChoicesStatic } from "../api/api";
import router from "../utils/router";

const LOAD_FROM_LOCAL_STORAGE = "LOAD_FROM_LOCAL_STORAGE";
const LOAD_SUCCESS_CHOICES = "LOAD_SUCCESS_CHOICES";
const LOAD_FAILURE_CHOICES = "LOAD_FAILURE_CHOICES";
const LOAD_CHOICES_REDUCER_ENDED_TYPE = "LOAD_CHOICES_REDUCER_ENDED_TYPE";
const LOAD_CHOICES_REDUCER_STARTED_TYPE = "LOAD_CHOICES_REDUCER_STARTED_TYPE";

export const loadFromLocalStorage = (choiceType) => ({
  type: LOAD_FROM_LOCAL_STORAGE,
  choiceType,
});

const loadSuccess = (data) => ({
  type: LOAD_SUCCESS_CHOICES,
  data,
});

const loadFailure = (data) => ({
  type: LOAD_FAILURE_CHOICES,
  data,
});

// const loadStarted = (choiceType) => ({
//   type: LOAD_CHOICES_REDUCER_STARTED_TYPE,
//   choiceType,
// });

const loadEnded = (choiceType) => ({
  type: LOAD_CHOICES_REDUCER_ENDED_TYPE,
  choiceType,
});

export const SHOW_ALL_OPTION = {
  value: "ALL",
  label: "Show All",
};

export const CHOICE_TYPES = {
  STATIC: "static",
  DYNAMIC: "dynamic",
};

export const CHOICE_TYPE = {
  COUNTRY: "country",
  YEAR: "year",
  DONOR: "donor",
  REPORT: "report",
  CATEGORY: "category",
  ORGANIZATION: "organization",
};

export const CHOICE_TYPE_OPTIONS = {
  COUNTRY: "country_name",
  YEAR: "spr_year",
  DONOR: "donor_name",
  CATEGORY: "project_category",
  REPORT: "spr-type",
};

const mapValues = (values, reversed = false) => {
  let orderedValues = reversed ? values.reverse() : values;

  return R.prepend(
    SHOW_ALL_OPTION,
    orderedValues.map((item) => {
      let mapped = {};

      mapped = R.assoc("value", item[0], mapped);
      mapped = R.assoc("label", item[1], mapped);

      return mapped;
    })
  );
};

const choiceTypes = (choiceType) => {
  switch (choiceType) {
    case CHOICE_TYPE_OPTIONS.YEAR:
    case CHOICE_TYPE_OPTIONS.COUNTRY:
    case CHOICE_TYPE_OPTIONS.DONOR:
    case CHOICE_TYPE_OPTIONS.CATEGORY:
      return CHOICE_TYPES.DYNAMIC;
    case CHOICE_TYPE_OPTIONS.REPORT:
      return CHOICE_TYPES.STATIC;
    default:
      return CHOICE_TYPES.STATIC;
  }
};

export const loadChoices = (choiceType) => (dispatch) => {
  //dispatch(loadStarted(choiceType));

  let request = null;

  if (choiceTypes(choiceType) === CHOICE_TYPES.DYNAMIC) {
    request = fetchChoices;
  } else {
    request = fetchChoicesStatic;
  }
  return request(choiceType)
    .then((data) => {
      dispatch(loadEnded(choiceType));
      dispatch(loadSuccess({ data, choiceType }));

      window.localStorage.setItem(choiceType, JSON.stringify(data));

      return data;
    })
    .catch((error) => {
      const status = R.pathOr(null, ["response", "status"], error);

      if (status === 401) {
        router.navigate("/login");
      }

      dispatch(loadEnded(choiceType));
      dispatch(loadFailure({ error }));
    });
};

const mapChoiceType = (choiceType) => {
  switch (choiceType) {
    case CHOICE_TYPE_OPTIONS.COUNTRY:
      return CHOICE_TYPE.COUNTRY;
    case CHOICE_TYPE_OPTIONS.YEAR:
      return CHOICE_TYPE.YEAR;
    case CHOICE_TYPE_OPTIONS.DONOR:
      return CHOICE_TYPE.DONOR;
    case CHOICE_TYPE_OPTIONS.CATEGORY:
      return CHOICE_TYPE.CATEGORY;
    case CHOICE_TYPE_OPTIONS.REPORT:
      return CHOICE_TYPE.REPORT;
    default:
      return "";
  }
};

const saveLocalChoices = (state, choiceType) => {
  const data = window.localStorage.getItem(choiceType);

  if (data) {
    return saveChoices(state, {
      choiceType: choiceType,
      data: JSON.parse(data),
    });
  } else {
    return state;
  }
};

const saveChoices = (state, action) => {
  switch (action.choiceType) {
    case CHOICE_TYPE_OPTIONS.COUNTRY:
      return R.assocPath(
        [CHOICE_TYPE.COUNTRY, "data"],
        mapValues(action.data.choices),
        state
      );
    case CHOICE_TYPE_OPTIONS.YEAR:
      return R.assocPath(
        [CHOICE_TYPE.YEAR, "data"],
        mapValues(action.data.choices, true),
        state
      );
    case CHOICE_TYPE_OPTIONS.DONOR:
      return R.assocPath(
        [CHOICE_TYPE.DONOR, "data"],
        mapValues(action.data.choices),
        state
      );
    case CHOICE_TYPE_OPTIONS.CATEGORY:
      return R.assocPath(
        [CHOICE_TYPE.CATEGORY, "data"],
        mapValues(action.data.choices),
        state
      );
    case CHOICE_TYPE_OPTIONS.REPORT:
      return R.assocPath(
        [CHOICE_TYPE.REPORT, "data"],
        mapValues(action.data.choices),
        state
      );
    default:
      return action;
  }
};

const saveLoadStarted = (state, action) => {
  return R.assocPath(
    [mapChoiceType(action.choiceType), "loading"],
    true,
    state
  );
};

const saveLoadEnded = (state, action) => {
  return R.assocPath(
    [mapChoiceType(action.choiceType), "loading"],
    false,
    state
  );
};

export default function choicesReducer(state = {}, action) {
  switch (action.type) {
    case LOAD_CHOICES_REDUCER_STARTED_TYPE: {
      return saveLoadStarted(state, action);
    }
    case LOAD_CHOICES_REDUCER_ENDED_TYPE: {
      return saveLoadEnded(state, action);
    }
    case LOAD_SUCCESS_CHOICES: {
      return saveChoices(state, action.data);
    }
    case LOAD_FROM_LOCAL_STORAGE: {
      return saveLocalChoices(state, action.choiceType);
    }
    default:
      return state;
  }
}
