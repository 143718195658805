import axios from "axios";
import { Auth } from "aws-amplify";

export const axiosInterceptors = {
  setupInterceptors: () => {
    axios.interceptors.request.use(
      async (config) => {
        const data = await Auth.currentSession();
        config.headers.Authorization = `${data.idToken?.jwtToken}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          //TODO: Do we need token renewal, most probably Amplify will handle it
        }
        return Promise.reject(error);
      }
    );
  },
};

export default axiosInterceptors;
