import * as R from "ramda";
import { fetchFile } from "../api/api";
import { SHOW_ALL_OPTION } from "./choicesReducer";
import router from "../utils/router";

const LOAD_STARTED_FILES = "LOAD_STARTED_FILES";
const LOAD_ENDED_FILES = "LOAD_ENDED_FILES";
const LOAD_SUCCESS_FILES = "LOAD_SUCCESS_FILES";
const LOAD_FAILURE_FILES = "LOAD_FAILURE_FILES";

export const clearFile = () => ({
  type: LOAD_SUCCESS_FILES,
  data: { data: { choices: [] } }
});

const loadStarted = () => ({
  type: LOAD_STARTED_FILES
});

const loadEnded = () => ({
  type: LOAD_ENDED_FILES
});

const loadFailure = data => ({
  type: LOAD_FAILURE_FILES,
  data
});

const mapValues = values => {
  return values.map(item => {
    let mapped = {};

    mapped = R.assoc("value", item[0], mapped);
    mapped = R.assoc("label", item[1], mapped);

    return mapped;
  });
};

export const loadFile = fileName => dispatch => {
  dispatch(loadStarted());

  return fetchFile(fileName)
    .then(data => { 
      const url = R.pathOr(null, ["downloadUrl"], data);

      if (url) {
        window.open(url);
      }

      dispatch(loadEnded());

      return data;
    })
    .catch(error => { 
      const status = R.pathOr(null, ["response", "status"], error);
      if (status === 401) {
        router.navigate("/login");
      }

      dispatch(loadEnded());
      dispatch(loadFailure({ error }));
    });
};

const saveFile = (state, action) => {
  return R.assocPath(
    ["data"],
    R.prepend(SHOW_ALL_OPTION, mapValues(action.data.choices)),
    state
  );
};

const saveLoading = (state, action) => {
  return R.assocPath(["loading"], action, state);
};

export default function organizationsReducer(
  state = { data: [], loading: false },
  action
) {
  switch (action && action.type) {
    case LOAD_STARTED_FILES: {
      return saveLoading(state, true);
    }
    case LOAD_ENDED_FILES: {
      return saveLoading(state, false);
    }
    case LOAD_SUCCESS_FILES: {
      return saveFile(state, action.data);
    }
    default:
      return state;
  }
}
