import * as R from "ramda";

export const ADD_TOKEN = "ADD_TOKEN";

export const addToken = token => {
  return {
    type: ADD_TOKEN,
    token
  };
};

const initialState = {
  token: null
};

export default function sessionReducer(state = initialState, action) {
  switch (action && action.type) {
    case ADD_TOKEN: {
      return R.assoc("token", action.token, state);
    }
    default:
      return state;
  }
} 
